<template>
  <a-image
    v-if="isSmall"
    :src="imageUrl"
    :alt="$options.consts.altText"
    v-bind="$options.consts.linkProps"
    class="mocked-events-banner__image small"
  />
  <a-image
    v-else-if="isLarge"
    :src="imageUrl"
    :alt="$options.consts.altText"
    v-bind="$options.consts.linkProps"
    :aspect-ratio="$options.consts.aspectRatios.homepageMockedEventBanner"
    class="mocked-events-banner__image large"
  />
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { getLinkToFmBucketFile } from '@/plugins/helper'
import { HTML_REL_ATTRIBUTE_FOLLOW_VALUE } from '@fmpedia/enums'
import { aspectRatios } from 'enums/aspectRatios'

export const SIZE = {
  SMALL_300x250: 'SMALL_300x250',
  LARGE_970x250: 'LARGE_970x250'
}

export default {
  name: 'AMockedEventsBanner',
  props: {
    size: propValidator([PROP_TYPES.STRING], true, null, v =>
      Object.values(SIZE).includes(v)
    )
  },
  consts: {
    aspectRatios,
    altText: 'Q3 2024 Intelligence Report Released',
    linkProps: {
      url: `https://www.financemagnates.com/intelligence/products/qir/q32024/`,
      directives: HTML_REL_ATTRIBUTE_FOLLOW_VALUE,
      openInNewTab: true,
      ariaLabel: 'Access the full report'
    }
  },
  data() {
    return {
      imageUrlBySize: {
        [SIZE.SMALL_300x250]: getLinkToFmBucketFile(
          'intelligence_banner_300x250.png'
        ),
        [SIZE.LARGE_970x250]: getLinkToFmBucketFile(
          'intelligence_banner_970x250.png'
        )
      }
    }
  },
  computed: {
    imageUrl() {
      return this.imageUrlBySize[this.size]
    },
    isSmall() {
      return this.size === SIZE.SMALL_300x250
    },
    isLarge() {
      return this.size === SIZE.LARGE_970x250
    }
  }
}
</script>

<style scoped lang="scss">
.mocked-events-banner__image {
  &.small {
    width: 300px;
    height: 250px;
  }

  &.large {
    width: 100%;
  }
}
</style>
